import React from 'react';

import {getIsEmoji} from 'common/actions';
import {MessageText} from '../../components';
import {Text} from 'components';
import {useChatMessageContext} from '../chat-message-context';
import UserMessage from '../../components/user-message/user-message';

const Message = () => {
  const {message} = useChatMessageContext();
  const isEmoji = getIsEmoji(message.content);

  return (
    <UserMessage>
      {isEmoji ? (
        <Text size={72}>{message.content}</Text>
      ) : (
        <MessageText>{message.content}</MessageText>
      )}
    </UserMessage>
  );
};

export default Message;
