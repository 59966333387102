import upd from 'immutability-helper';

import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import * as AppEnv from 'app-env';

const useHandleMessage = () => {
  const {setDialogs} = useWebSocketProviderContext();

  const handleMessage = (instance: AppEnv.Instance, msg: AppEnv.ChatMessage) =>
    setDialogs(prevValue => {
      if (!prevValue) return prevValue;

      const dialogIndex = prevValue[instance.id]?.findIndex(
        dialog => dialog.id == msg.dialog_id
      );

      if (dialogIndex == undefined || dialogIndex < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            messages: {
              $apply: (messages: AppEnv.ChatMessage[] | undefined) =>
                messages ? [...messages, msg] : [msg]
            }
          }
        }
      });
    });

  return handleMessage;
};

export default useHandleMessage;
