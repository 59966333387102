import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Fuse from 'fuse.js';

import {Placeholder, Preloader} from 'components';
import {
  useGetFolderDialogList,
  useScrollClassName,
  useSlice
} from 'common/hooks';
import {useDispatchContext} from '../../dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getUnreadDialogList, sortDialogs} from 'common/actions';
import * as AppEnv from 'app-env';

import Dialog from './dialog/dialog';

interface DialogList {
  search: string;
  isUnreadFilter: boolean;
}

const DialogList = ({search, isUnreadFilter}: DialogList) => {
  const {user} = useWorkspaceContext();
  const {dialogList, folder, instance} = useDispatchContext();

  const [fusedDialogList, setFusedDialogList] = useState<AppEnv.Dialog[]>();

  const {t} = useTranslation();
  const getFolderDialogList = useGetFolderDialogList();
  const ref = useRef<HTMLDivElement>(null);
  const [slice, setSlice] = useSlice(fusedDialogList, ref);

  useEffect(() => {
    let newFusedDialogList = getFolderDialogList(dialogList, folder);

    if (isUnreadFilter)
      newFusedDialogList = getUnreadDialogList(newFusedDialogList);

    const fuse = new Fuse(newFusedDialogList, {
      keys: ['id.user', 'id', 'manager.email', 'manager.name', 'name'],
      threshold: 0.25
    });

    if (search) newFusedDialogList = fuse.search(search).map(item => item.item);
    newFusedDialogList.sort(sortDialogs);
    setFusedDialogList(newFusedDialogList);
  }, [dialogList, folder, instance, isUnreadFilter, search, user]);

  useEffect(() => {
    if (fusedDialogList?.length) ref.current?.scrollTo(0, 0);
  }, [fusedDialogList?.length]);

  useEffect(() => {
    setSlice(16);
  }, [instance.id]);

  const scrollClassName = useScrollClassName();

  const isLoaded = !!(dialogList && fusedDialogList);

  return (
    <div
      ref={ref}
      className={cn('dialogs__list', {[scrollClassName]: scrollClassName})}
    >
      {isLoaded ? (
        fusedDialogList.length ? (
          fusedDialogList
            .slice(0, slice)
            .map(item => (
              <Dialog
                key={item.version == 'whatcrm' ? item.id._serialized : item.id}
                dialog={item}
              />
            ))
        ) : (
          <Placeholder title={t`No chats`} isMaxHeight />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </div>
  );
};

export default DialogList;
