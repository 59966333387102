import {useState} from 'react';

import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {
  getDialogId,
  getIsMessageLimited,
  getMessageTimestamp
} from 'common/actions';
import * as AppEnv from 'app-env';

import useUpdMsgList from './use-upd-msg-list/use-upd-msg-list';

const useMsgList = (setIsAllMsgsReceived: AppEnv.SetState<boolean>) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(dialog.messages?.length || 0); // Avito | Chat

  const {
    fetchMessagesAvito,
    fetchMessagesChat,
    fetchMessagesTelegram,
    fetchMessagesWhatsApp
  } = useRequest();

  const updMsgList = useUpdMsgList();

  const ga = () => {
    const id = getDialogId(dialog);
    if (typeof id != 'string') return;

    return fetchMessagesAvito(instance.chat_key, id, offset);
  };

  const gc = () => {
    const id = getDialogId(dialog);
    if (typeof id != 'string') return;

    return fetchMessagesChat(instance.chat_key, id, offset);
  };

  const gt = () => {
    if (dialog.version != 'telegram') return;

    const id = getDialogId(dialog);
    if (typeof id != 'number') return;

    const fromMessage = dialog.messages?.[0];
    const fromMessageId = fromMessage?.id;

    const noNotification = dialog.version == 'telegram' && !!dialog.username;

    return fetchMessagesTelegram(instance.chat_key, id, {
      fromMessageId,
      noNotification
    });
  };

  const gw = () => {
    const id = getDialogId(dialog, true);
    if (typeof id != 'string') return;

    const latestMsg = dialog.messages?.[0];
    const maxTimestamp = latestMsg ? getMessageTimestamp(latestMsg) : undefined;

    return fetchMessagesWhatsApp(instance.chat_key, id, maxTimestamp);
  };

  const gm = () => {
    switch (dialog.version) {
      case 'avito':
        return ga();
      case 'chat':
        return gc();
      case 'telegram':
        return gt();
      case 'whatcrm':
        return gw();
    }
  };

  const getMsgList = async (isAllMsgsReceived: boolean) => {
    if (isAllMsgsReceived || isFetching) return;

    setIsFetching(true);
    const res = await gm();

    const msgs = (Array.isArray(res) ? res : res?.messages)?.filter(
      item => !getIsMessageLimited(instance, item)
    );

    if (!msgs?.length) setIsAllMsgsReceived(true);

    setOffset(prevValue => prevValue + (msgs?.length || 0));
    updMsgList(msgs || []);
    setIsFetching(false);
  };

  return {getMsgList};
};

export default useMsgList;
