import React from 'react';
import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {SelectManager} from '../components';
import {useDialogContext} from '../dialog-context';
import {useDispatchContext} from '../../../../dispatch-context';
import {useGetChatManager} from '../hooks';
import {useMessengerContext} from 'pages/workspace/pages/messenger/messenger-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface ManagerAssign {
  isManagerAssignActive: boolean;
  setIsManagerAssignActive: AppEnv.SetState<boolean>;
}

const ManagerAssign = ({
  isManagerAssignActive,
  setIsManagerAssignActive
}: ManagerAssign) => {
  const {setDialogs} = useMessengerContext();
  const {instance} = useDispatchContext();
  const {dialog} = useDialogContext();

  const {fetchAssign} = useRequest();
  const getChatManager = useGetChatManager();

  const handleSubmit = async (managerId: number) => {
    const dialogId = getDialogId(dialog);
    if (typeof dialogId != 'string') return true;

    const chatManager = await getChatManager(managerId);
    if (!chatManager) return true;

    const res = await fetchAssign(instance.chat_key, dialogId, chatManager.id);

    if (!res?.success) return true;

    setDialogs(prevValue => {
      const index = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item) == dialogId
      );

      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {
          [index]: {
            managers: {
              $apply: (managers: AppEnv.ChatManager[] | undefined) =>
                managers ? [...managers, chatManager] : [chatManager]
            }
          }
        }
      });
    });

    setIsManagerAssignActive(false);
    return true;
  };

  return (
    <SelectManager
      isActive={isManagerAssignActive}
      onSubmit={handleSubmit}
      setIsActive={setIsManagerAssignActive}
    />
  );
};

export default ManagerAssign;
