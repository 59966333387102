import React from 'react';

import {IconsAlt, Text} from 'components';
import {useDialogContext} from '../dialog-context';

const Managers = () => {
  const {dialog} = useDialogContext();

  const {managers = []} = dialog.version == 'chat' ? dialog : {};

  const names = managers
    .map(manager => manager.name || manager.email)
    .join(', ');

  return (
    <>
      {names.length > 0 && (
        <div className="dialog__manager">
          <IconsAlt.ManagerFill />

          <Text fontWeight={600} size={12} style={{marginBottom: 2}}>
            {names}
          </Text>
        </div>
      )}
    </>
  );
};

export default Managers;
