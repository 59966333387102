import {useEffect, useState} from 'react';
import upd from 'immutability-helper';

import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useOpenWs from './use-open-ws/use-open-ws';

const useChatWs = () => {
  const {activeInstances} = useWorkspaceContext();
  const [channels, setChannels] = useState<number[]>([]);
  const openWs = useOpenWs();

  useEffect(() => {
    activeInstances.forEach(instance => {
      if (instance.version != 'chat') return;

      const isChannel = channels.some(channel => channel == instance.id);
      if (isChannel) return;

      openWs(instance);
      setChannels(prevValue => upd(prevValue, {$push: [instance.id]}));
    });
  }, [activeInstances.length]);
};

export default useChatWs;
