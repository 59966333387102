import {IMessageEvent} from 'websocket';

import {getMessageEventData} from 'common/actions';
import * as AppEnv from 'app-env';

import useHandleAddReaction from './use-handle-add-reaction/use-handle-add-reaction';
import useHandleMessage from './use-handle-message/use-handle-message';
import useHandleRemoveReaction from './use-handle-remove-reaction/use-handle-remove-reaction';

interface Data {
  event: 'add_reaction' | 'message' | 'remove_reaction';
  message: AppEnv.ChatMessage;
}

const useHandleEvent = () => {
  const handleAddReaction = useHandleAddReaction();
  const handleMessage = useHandleMessage();
  const handleRemoveReaction = useHandleRemoveReaction();

  const handleEvent = (
    instance: AppEnv.Instance,
    messageEvent: IMessageEvent
  ) => {
    const data = getMessageEventData<Data>(messageEvent);

    if (!data) return;
    else if (data.event == 'add_reaction')
      handleAddReaction(instance, data.message);
    else if (data.event == 'message') handleMessage(instance, data.message);
    else if (data.event == 'remove_reaction')
      handleRemoveReaction(instance, data.message);
  };

  return handleEvent;
};

export default useHandleEvent;
