import React from 'react';
import {useTranslation} from 'react-i18next';

import {ContextMenuOption, IconsAlt} from 'components';
import {useUnbindManager} from '../..';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useUnbind = (
  dialog: AppEnv.Dialog,
  setIsManagerUnbindActive: AppEnv.SetState<boolean>
): ContextMenuOption | null => {
  const {getManagerList} = useWorkspaceContext();

  const {t} = useTranslation();
  const unbindManager = useUnbindManager(dialog);

  const handleClick = async () => {
    if (dialog.version != 'chat') return;
    else if (!dialog.managers) return;
    else if (dialog.managers.length > 1) return setIsManagerUnbindActive(true);

    const [chatManager] = dialog.managers;
    const managerList = await getManagerList();

    const manager = managerList.find(item => item.email == chatManager.email);
    if (!manager) return;

    unbindManager(manager.id);
  };

  if (dialog.version != 'chat') return null;
  else if (!dialog.managers) return null;
  else if (dialog.managers.length < 1) return null;

  const unbind: ContextMenuOption = {
    icon: <IconsAlt.Manager />,
    label: t`Unassign responsible`,
    onClick: handleClick
  };

  return unbind;
};

export default useUnbind;
