import {useState} from 'react';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetManagerList = (user: AppEnv.User) => {
  const [managerList, setManagerList] = useState<AppEnv.Manager[]>();
  const {fetchManagersList} = useRequest();

  const getManagerList = async () => {
    if (managerList) return managerList;

    const {integration} = user;
    const res = await fetchManagersList(integration.id);

    const newManagerList = res || [];
    setManagerList(newManagerList);

    return newManagerList;
  };

  return getManagerList;
};

export default useGetManagerList;
