import React, {useRef} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Textarea} from 'components';
import Vars from './vars/vars';

const Message = () => {
  const [field, meta, helpers] = useField<string>('message');
  const {t} = useTranslation();
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const isError = !!meta.error && meta.touched;

  return (
    <div>
      <Textarea
        {...field}
        isAlt
        isError={isError}
        onChange={helpers.setValue}
        placeholder={t`Message`}
        ref={messageRef}
        style={{marginBottom: 8}}
      />

      <Vars messageRef={messageRef} />
    </div>
  );
};

export default Message;
