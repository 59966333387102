import {useRef} from 'react';
import {w3cwebsocket} from 'websocket';

import * as AppEnv from 'app-env';
import useHandleEvent from './use-handle-event/use-handle-event';

const useOpenWs = () => {
  const count = useRef(0);
  const handleEvent = useHandleEvent();
  const ws = useRef<w3cwebsocket | null>(null);

  const handleClose = (instance: AppEnv.Instance) => {
    if (count.current > 1) return;
    count.current += 1;

    setTimeout(() => openWs(instance), 1500);
  };

  const openWs = async (instance: AppEnv.Instance) => {
    const {access_manager} = instance;
    if (!access_manager?.access_token) return;

    try {
      ws.current = new w3cwebsocket('wss://chat.whatcrm.net/ws/');

      ws.current.binaryType = 'arraybuffer';
      ws.current.onclose = () => handleClose(instance);

      ws.current.onmessage = messageEvent =>
        handleEvent(instance, messageEvent);

      ws.current.onopen = () =>
        ws.current?.send(
          JSON.stringify({
            token: access_manager.access_token,
            type: 'X-Chat-Token'
          })
        );
    } catch {}
  };

  return openWs;
};

export default useOpenWs;
