import upd from 'immutability-helper';

import * as AppEnv from 'app-env';
import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';

const useHandleAddReaction = () => {
  const {setDialogs} = useWebSocketProviderContext();

  const handleAddReaction = (
    instance: AppEnv.Instance,
    msg: AppEnv.ChatMessage
  ) =>
    setDialogs(prevValue => {
      if (!prevValue) return prevValue;

      const dialogIndex =
        prevValue[instance.id]?.findIndex(item => item.id == msg.dialog_id) ??
        -1;

      const dialog = prevValue[instance.id]?.[dialogIndex];
      if (!dialog) return prevValue;

      const messageIndex =
        dialog.messages?.findIndex(item => item.id == msg.quotedMsgId) ?? -1;

      const message = dialog.messages?.[messageIndex];

      if (!message) return prevValue;
      else if (!('sender_id' in message)) return prevValue;

      const reaction: AppEnv.ChatReaction = {
        symbol: message.content,
        user_id: message.sender_id
      };

      if (!message.reactions)
        return upd(prevValue, {
          [instance.id]: {
            [dialogIndex]: {
              messages: {[messageIndex]: {reactions: {$set: [reaction]}}}
            }
          }
        });

      const reactionIndex = message.reactions.findIndex(
        item => item.user_id == reaction.user_id
      );

      if (reactionIndex == undefined || reactionIndex < 0)
        return upd(prevValue, {
          [instance.id]: {
            [dialogIndex]: {
              messages: {[messageIndex]: {reactions: {$push: [reaction]}}}
            }
          }
        });

      return upd(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            messages: {
              [messageIndex]: {reactions: {[reactionIndex]: {$set: reaction}}}
            }
          }
        }
      });
    });

  return handleAddReaction;
};

export default useHandleAddReaction;
