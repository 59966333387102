import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

import {Placeholder, Preloader, RadioAlt} from 'components';
import {useDialogContext} from '../../../dialog-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Manager from './manager/manager';

interface ManagerId {
  isUnbind: boolean;
}

interface Option {
  label: React.ReactNode;
  value: number;
}

const ManagerId = ({isUnbind}: ManagerId) => {
  const {getManagerList} = useWorkspaceContext();
  const {dialog} = useDialogContext();
  const {submitForm} = useFormikContext();

  const [options, setOptions] = useState<Option[]>();

  const [field, , helpers] = useField<number | undefined>('managerId');
  const {t} = useTranslation();

  const filter = (manager: AppEnv.Manager) => {
    if (dialog.version != 'chat') return false;

    const isResponsible =
      dialog.managers?.some(item => item.email == manager.email) ?? false;

    return isResponsible == isUnbind;
  };

  const map = (manager: AppEnv.Manager) => ({
    label: <Manager manager={manager} />,
    value: manager.id
  });

  const getOptions = async () => {
    const res = await getManagerList();
    const newOptions = res.filter(filter).map(map);

    setOptions(newOptions);
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <>
      {options ? (
        options.length > 0 ? (
          <RadioAlt
            {...field}
            onChange={helpers.setValue}
            onSubmit={() => submitForm()}
            options={options}
          />
        ) : (
          <Placeholder title={t`No staff members`} />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default ManagerId;
