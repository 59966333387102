import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons} from 'components';
import {
  useRequestBitrix24,
  useRequestKommo,
  useRequestMonday,
  useRequestPipedrive
} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

interface ManagerSync {
  updateManagerList: () => Promise<void>;
}

const ManagerSync = ({updateManagerList}: ManagerSync) => {
  const {user} = useWorkspaceContext();
  const [isLoad, setIsLoad] = useState(false);

  const {fetchEmployees} = useRequestPipedrive(user.integration);
  const {fetchManagersRefresh} = useRequestMonday(user.integration);
  const {fetchPrefs: fetchPrefsBitrix24} = useRequestBitrix24(user.integration);
  const {fetchPrefs: fetchPrefsKommo} = useRequestKommo(user.integration);
  const {t} = useTranslation();

  const handleClick = async () => {
    const {integration} = user;
    let res;

    setIsLoad(true);

    if (['AMO', 'TELEGRAM'].includes(integration.type))
      res = await fetchPrefsKommo(integration.domain);
    else if (['B24', 'TELPHGRAM'].includes(integration.type))
      res = await fetchPrefsBitrix24(integration.domain);
    else if (['MONDAY', 'TGMONDAY'].includes(integration.type))
      res = await fetchManagersRefresh(integration.domain);
    else if (['PIPEDRIVE'].includes(integration.type))
      res = await fetchEmployees(integration.domain);

    if (!res) return setIsLoad(false);

    await updateManagerList();
    setIsLoad(false);
  };

  const {integration} = user;

  const isVisible = [
    'AMO',
    'B24',
    'MONDAY',
    'PIPEDRIVE',
    'TELEGRAM',
    'TELPHGRAM',
    'TGMONDAY',
    'TGMONDAY'
  ].includes(integration.type);

  return (
    <>
      {isVisible && (
        <Button
          color="blue-dash"
          onClick={handleClick}
          state={isLoad ? 'disabled' : undefined}
          style={{marginBottom: 16}}
        >
          <span>{t`Refresh`}</span>

          <Icons.ArrowClockwise
            behavior={isLoad ? 'rotate-clockwise' : undefined}
            color="blue"
            size={20}
          />
        </Button>
      )}
    </>
  );
};

export default ManagerSync;
