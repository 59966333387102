import upd from 'immutability-helper';

import {getDialogId} from 'common/actions';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import {useGetChatManager} from '..';
import {useMessengerContext} from 'pages/workspace/pages/messenger/messenger-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useUnbindManager = (dialog: AppEnv.Dialog) => {
  const {setDialogs} = useMessengerContext();
  const {instance} = useDispatchContext();

  const {fetchUnbind} = useRequest();
  const getChatManager = useGetChatManager();

  const unbindManager = async (managerId: number) => {
    const dialogId = getDialogId(dialog, true);
    if (typeof dialogId != 'string') return false;

    const chatManager = await getChatManager(managerId);
    if (!chatManager) return false;

    const res = await fetchUnbind(instance.chat_key, dialogId, chatManager.id);

    if (dialog.version != 'chat') return false;
    else if (!res?.success) return false;

    const managerIndex = dialog.managers?.findIndex(
      manager => manager.id == chatManager.id
    );

    if (managerIndex == undefined || managerIndex < 0) return false;

    setDialogs(prevValue => {
      const dialogIndex = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item) == getDialogId(dialog)
      );

      if (dialogIndex == undefined || dialogIndex < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {
          [dialogIndex]: {managers: {$splice: [[managerIndex, 1]]}}
        }
      });
    });

    return true;
  };

  return unbindManager;
};

export default useUnbindManager;
