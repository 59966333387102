import React, {useState} from 'react';

import {Icons} from 'components';
import WriteFirst from '../../components/write-first/write-first';

const NewChat = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="dialogs__creator">
        <button className="dialogs__button" onClick={() => setIsActive(true)}>
          <Icons.Plus />
        </button>
      </div>

      <WriteFirst isActive={isActive} setIsActive={setIsActive} />
    </>
  );
};

export default NewChat;
