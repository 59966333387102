import {fetchFile} from '@ffmpeg/util';
import mime from 'mime';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useUploadVoice = (version: AppEnv.InstanceVersion | 'whatsapp') => {
  const {getFfmpeg, isCanPlayOgg} = useAppContext();
  const {postFilesLoadData} = useRequest();

  const getMp3Blob = async (blob: Blob) => {
    const ffmpeg = await getFfmpeg();

    const ext = mime.getExtension(blob.type);
    const input = `input.${ext}`;
    const output = 'output.mp3';

    await ffmpeg.writeFile(input, await fetchFile(blob));
    await ffmpeg.exec(['-i', input, output]);

    const fileData = await ffmpeg.readFile(output);
    const mp3Blob = new Blob([fileData], {type: 'audio/mp3'});

    return mp3Blob;
  };

  const uploadVoice = async (blob: Blob) => {
    const isMp3 =
      !isCanPlayOgg || version == 'whatcrm' || version == 'whatsapp';

    const blobValue = isMp3 ? await getMp3Blob(blob) : blob;
    const ext = isMp3 ? 'mp3' : 'oga';
    const filename = `voice.${ext}`;
    const src = await postFilesLoadData(blobValue, filename);

    return src;
  };

  return uploadVoice;
};

export default useUploadVoice;
