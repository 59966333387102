import React from 'react';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import * as AppEnv from 'app-env';

import ManagerId from './manager-id/manager-id';

interface SelectManager {
  isActive: boolean;
  isUnbind?: boolean;
  onSubmit: (managerId: number) => Promise<boolean>;
  setIsActive: AppEnv.SetState<boolean>;
}

interface Values {
  managerId: number | undefined;
}

const initialValues: Values = {managerId: undefined};

const validationSchema = yup
  .object()
  .shape({managerId: yup.number().required().positive()});

const SelectManager = ({
  isActive,
  isUnbind = false,
  onSubmit,
  setIsActive
}: SelectManager) => {
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (typeof values.managerId != 'number') return true;
    return onSubmit(values.managerId);
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)} width={408}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({values}) => (
          <Form>
            <PopupWrapper>
              <Title.H3 style={{marginBottom: 16}}>
                {isUnbind
                  ? t`Unassign responsible staff member`
                  : t`Assign responsible staff member`}
              </Title.H3>

              <ManagerId isUnbind={isUnbind} />
            </PopupWrapper>

            <PopupFooter>
              <Wrapper gap={12}>
                <SaveButton
                  state={values.managerId == undefined ? 'disabled' : undefined}
                >
                  {isUnbind ? t`Unassign` : t`Assign`}
                </SaveButton>

                <Button color="fade" onClick={() => setIsActive(false)}>
                  {t`Close`}
                </Button>
              </Wrapper>
            </PopupFooter>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};

export default SelectManager;
