import React from 'react';
import cn from 'classnames';

interface IconAlt {
  isScaleX?: boolean;
  name:
    | 'brush'
    | 'check-square-offset'
    | 'download-simple'
    | 'files'
    | 'manager-fill'
    | 'manager'
    | 'notification'
    | 'pencil-simple'
    | 'push-pin-simple-slash'
    | 'push-pin-simple'
    | 'question'
    | 'reply'
    | 'trash-simple';
}

const IconAlt = ({isScaleX, name}: IconAlt) => (
  <div
    className={cn('icon-alt', {
      'icon-alt_scale-x': isScaleX,
      [`icon-alt_${name}`]: name
    })}
  />
);

const IconsAlt = {
  Brush: () => <IconAlt name="brush" />,
  CheckSquareOffset: () => <IconAlt name="check-square-offset" />,
  DownloadSimple: () => <IconAlt name="download-simple" />,
  Files: () => <IconAlt name="files" />,
  Forward: () => <IconAlt name="reply" isScaleX />,
  Manager: () => <IconAlt name="manager" />,
  ManagerFill: () => <IconAlt name="manager-fill" />,
  Notification: () => <IconAlt name="notification" />,
  PencilSimple: () => <IconAlt name="pencil-simple" />,
  PushPinSimple: () => <IconAlt name="push-pin-simple" />,
  PushPinSimpleSlash: () => <IconAlt name="push-pin-simple-slash" />,
  Question: () => <IconAlt name="question" />,
  Reply: () => <IconAlt name="reply" />,
  TrashSimple: () => <IconAlt name="trash-simple" />
};

export default IconsAlt;
