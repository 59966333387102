import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type UpdateMessageBody = (
  messageId: number | string,
  body: string,
  editDate?: number
) => void;

interface WorkspaceContext {
  activeInstances: AppEnv.Instance[];
  connectorId: number | undefined;
  getManagerList: () => Promise<AppEnv.Manager[]>;
  instanceReferer: string | undefined;
  responsibleList: AppEnv.Responsible[];
  setConnectorId: AppEnv.SetState<number | undefined>;
  setInstanceReferer: AppEnv.SetState<string | undefined>;
  setResponsibleList: AppEnv.SetState<AppEnv.Responsible[]>;
  setUser: AppEnv.SetState<AppEnv.User | null | undefined>;
  updateMessageBody: UpdateMessageBody;
  updateQuotedMsgBody: (messageId: number, body: string) => void;
  user: AppEnv.User;
}

const WorkspaceContext = React.createContext<WorkspaceContext>(
  {} as WorkspaceContext
);

export const useWorkspaceContext = () => useContext(WorkspaceContext);
export default WorkspaceContext;
