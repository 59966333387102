import React from 'react';

import {SelectManager} from '../components';
import {useDialogContext} from '../dialog-context';
import {useUnbindManager} from '../hooks';
import * as AppEnv from 'app-env';

interface ManagerUnbind {
  isManagerUnbindActive: boolean;
  setIsManagerUnbindActive: AppEnv.SetState<boolean>;
}

const ManagerUnbind = ({
  isManagerUnbindActive,
  setIsManagerUnbindActive
}: ManagerUnbind) => {
  const {dialog} = useDialogContext();

  const unbindManager = useUnbindManager(dialog);

  const handleSubmit = async (managerId: number) => {
    const res = await unbindManager(managerId);
    if (!res) return true;

    setIsManagerUnbindActive(false);
    return true;
  };

  return (
    <SelectManager
      isActive={isManagerUnbindActive}
      isUnbind
      onSubmit={handleSubmit}
      setIsActive={setIsManagerUnbindActive}
    />
  );
};

export default ManagerUnbind;
