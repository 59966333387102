import React from 'react';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import useAvitoWs from './use-avito-ws/use-avito-ws';
import useEventsWs from './use-events-ws/use-events-ws';
import useWhatcrmWs from './use-whatcrm-ws/use-whatcrm-ws';
import useChatWs from './use-chat-ws/use-chat-ws';

const WebSocket = () => {
  const {activeInstances} = useWorkspaceContext();

  const isAvito = activeInstances.some(instance => instance.version == 'avito');
  const isChat = activeInstances.some(instance => instance.version == 'chat');

  const isWhatcrm = activeInstances.some(instance =>
    ['telegram', 'whatcrm'].includes(instance.version)
  );

  if (activeInstances.length) useEventsWs();
  if (isAvito) useAvitoWs();
  if (isChat) useChatWs();
  if (isWhatcrm) useWhatcrmWs();

  return <></>;
};

export default WebSocket;
