import React, {useState, useRef} from 'react';
import {LongPressReactEvents, useLongPress} from 'use-long-press';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Confirm, ContextMenu, Wrapper} from 'components';
import {
  getAvitoProductImage,
  getDialogId,
  getDialogName,
  getDialogProfileImage,
  getIsDialogUnread
} from 'common/actions';
import {useAppContext} from 'app-context';
import {useDeleteDialog} from '../../../hooks';
import {useDispatchContext} from 'pages/workspace/pages/messenger/dispatch/dispatch-context';
import * as AppEnv from 'app-env';
import ProfileImage, {
  useProfileImage
} from 'pages/workspace/pages/messenger/dispatch/components/profile-image/profile-image';

import {useMenuOptions} from './hooks';
import Badges from './badges/badges';
import DialogContext from './dialog-context';
import ManagerAssign from './manager-assign/manager-assign';
import Managers from './managers/managers';
import ManagerUnbind from './manager-unbind/manager-unbind';
import Message from './message/message';
import Metadata from './metadata/metadata';
import Name from './name/name';

interface TriggerPosition {
  left: number;
  top: number;
}

interface Dialog {
  dialog: AppEnv.Dialog;
}

const Dialog = ({dialog}: Dialog) => {
  const {isIframe} = useAppContext();
  const {instance} = useDispatchContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [isManagerAssignActive, setIsManagerAssignActive] = useState(false);
  const [isManagerUnbind, setIsManagerUnbindActive] = useState(false);
  const [triggerPosition, setTriggerPosition] = useState<TriggerPosition>();

  const {dialogId} = useParams();
  const {t} = useTranslation();

  const bindLongPress = useLongPress(e => handleContextMenu(e), {
    cancelOnMovement: true
  });

  const deleteDialog = useDeleteDialog(dialog, setIsConfirmActive);
  const dialogRef = useRef<HTMLDivElement>(null);

  const menuOptions = useMenuOptions(dialog, {
    setIsConfirmActive,
    setIsManagerAssignActive,
    setIsManagerUnbindActive
  });

  const navigate = useNavigate();
  useProfileImage(dialog, dialogRef);

  const getIsActive = () => {
    if (!dialogId) return false;

    if (['avito', 'chat', 'telegram'].includes(dialog.version))
      return dialog.id == dialogId;
    else if (dialog.version == 'whatcrm') return dialog.id.user == dialogId;

    return false;
  };

  const getTitle = () => {
    if (dialog.version == 'whatcrm')
      return t`Are you sure you want to clear this chat?`;

    return t`Are you sure you want to delete this chat?`;
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsContextMenuActive(false);

    const id = getDialogId(dialog);
    const href = `/messenger/${instance.id}/${id}`;
    navigate(href, {replace: isIframe});
  };

  const handleContextMenu = (e: MouseEvent | LongPressReactEvents<Element>) => {
    if (!menuOptions.length) return;
    e.preventDefault();

    if (dialog.version == 'avito' && !getIsDialogUnread(dialog)) return;
    else if ('pageX' in e) setTriggerPosition({left: e.pageX, top: e.pageY});

    setIsContextMenuActive(true);
  };

  const handleDelete = (res: boolean) =>
    res ? deleteDialog() : setIsConfirmActive(false);

  const isActive = getIsActive();
  const isManagers = dialog.version == 'chat' && dialog.managers;

  const src =
    dialog.version == 'avito'
      ? getAvitoProductImage(dialog)
      : getDialogProfileImage(instance, dialog);

  const title = getTitle();

  return (
    <DialogContext.Provider value={{dialog}}>
      <div>
        <div
          {...bindLongPress()}
          className={cn('dialog', {dialog_active: isActive})}
          ref={dialogRef}
          onClick={handleClick}
          onContextMenu={handleContextMenu}
        >
          {isManagers && <Managers />}

          <Wrapper
            alignItems="center"
            flexWrap="nowrap"
            gap={12}
            justifyContent="space-between"
          >
            <ProfileImage
              id={getDialogId(dialog)}
              name={getDialogName(instance, dialog)}
              src={src}
              tag="version"
            />

            <div className="dialog__wrapper">
              <div className="dialog__top">
                <Name />
                <Metadata />
              </div>

              <div className="dialog__bottom">
                <Message />
                <Badges />
              </div>
            </div>
          </Wrapper>
        </div>

        <Confirm
          isActive={isConfirmActive}
          onClick={handleDelete}
          title={title}
        />

        <ContextMenu
          isActive={isContextMenuActive}
          options={menuOptions}
          setIsActive={setIsContextMenuActive}
          triggerPosition={triggerPosition}
          triggerRef={dialogRef}
        />

        <ManagerAssign
          isManagerAssignActive={isManagerAssignActive}
          setIsManagerAssignActive={setIsManagerAssignActive}
        />

        <ManagerUnbind
          isManagerUnbindActive={isManagerUnbind}
          setIsManagerUnbindActive={setIsManagerUnbindActive}
        />
      </div>
    </DialogContext.Provider>
  );
};

export default Dialog;
