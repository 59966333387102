import React from 'react';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {Acks} from 'app-enums';
import {getIsMessageOutgoing} from 'common/actions';
import {Icons, Text, Tooltip} from 'components';
import * as AppEnv from 'app-env';

interface Ack {
  dialog: AppEnv.Dialog;
  instance: AppEnv.Instance;
  message: AppEnv.LastMesssage | AppEnv.Message;
}

const getAck = (dialog: AppEnv.Dialog, message: AppEnv.AnyMessage) => {
  const isOutgoing = getIsMessageOutgoing(dialog, message);
  if (!isOutgoing) return;

  if ('author_id' in message && message.direction == 'out') {
    return message.read ? Acks.Read : Acks.Delivered;
  } else if ('sender_id' in message) {
    if (message.status == 'delivered') return Acks.Delivered;
    else if (message.status == 'read') return Acks.Read;
  } else if (dialog.version == 'telegram' && 'senderId' in message) {
    if ('sendingState' in message) {
      if (message.sendingState?._ == 'messageSendingStatePending')
        return Acks.Pending;
      else if (message.sendingState?._ == 'messageSendingStateFailed')
        return Acks.Failed;
    }

    return dialog.lastReadOutboxMessageId < message.id
      ? Acks.Delivered
      : Acks.Read;
  } else if ('_data' in message && message.fromMe) {
    return message.ack;
  }
};

const Ack = ({dialog, instance, message}: Ack) => {
  const {t} = useTranslation();

  const ack = getAck(dialog, message);
  const isTooltipShown = 'senderId' in message && message.sendingState?.error;

  return (
    <>
      {ack != undefined && (
        <div
          className={cn('ack', {[`ack_${instance.version}`]: instance.version})}
        >
          {ack == Acks.Sent ? (
            <Icons.Ack className="ack__sent" />
          ) : ack === Acks.Delivered ? (
            instance.version == 'whatcrm' ? (
              <Icons.Ack2 className="ack__delivered" />
            ) : (
              <Icons.Ack className="ack__delivered" />
            )
          ) : ack == Acks.Read || ack == Acks.Played ? (
            <Icons.Ack2 className="ack__read" />
          ) : ack == Acks.Pending ? (
            <Icons.AckPending />
          ) : (
            ack == Acks.Failed && <Icons.AckFailed color="red" />
          )}

          {isTooltipShown && (
            <Tooltip>
              <Text fontWeight={600} size={14}>
                {`${t`Error`} ${message.sendingState?.error?.code}`}
              </Text>

              <Text color="white" size={12}>
                {message.sendingState?.error?.message}
              </Text>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

export default Ack;
