import upd from 'immutability-helper';

import {useWebSocketProviderContext} from 'pages/workspace/web-socket-provider/web-socket-context';
import * as AppEnv from 'app-env';

const useHandleRemoveReaction = () => {
  const {setDialogs} = useWebSocketProviderContext();

  const handleRemoveReaction = (
    instance: AppEnv.Instance,
    msg: AppEnv.ChatMessage
  ) =>
    setDialogs(prevValue => {
      const dialogIndex =
        prevValue?.[instance.id]?.findIndex(item => item.id == msg.dialog_id) ??
        -1;

      const dialog = prevValue?.[instance.id]?.[dialogIndex];
      if (!dialog) return prevValue;

      const messsgeIndex =
        dialog.messages?.findIndex(item => item.id == msg.quotedMsgId) ?? -1;
      const message = dialog.messages?.[messsgeIndex];

      if (!message) return prevValue;
      else if (!('sender_id' in message)) return prevValue;

      const reactionIndex = message.reactions?.findIndex(
        item => item.user_id == msg.sender_id
      );
      if (reactionIndex == undefined || reactionIndex < 0) return prevValue;

      return upd(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            messages: {
              [messsgeIndex]: {reactions: {$splice: [[reactionIndex, 1]]}}
            }
          }
        }
      });
    });

  return handleRemoveReaction;
};

export default useHandleRemoveReaction;
