import {ContextMenuOption} from 'components';
import * as AppEnv from 'app-env';

import useClear from './use-clear/use-clear';
import useManager from './use-manager/use-manager';
import usePin from './use-pin/use-pin';
import useRead from './use-read/use-read';
import useUnbind from './use-unbind/use-unbind';

interface MenuOptionsParams {
  setIsConfirmActive: AppEnv.SetState<boolean>;
  setIsManagerAssignActive: AppEnv.SetState<boolean>;
  setIsManagerUnbindActive: AppEnv.SetState<boolean>;
}

const useMenuOptions = (
  dialog: AppEnv.Dialog,
  {
    setIsConfirmActive,
    setIsManagerAssignActive,
    setIsManagerUnbindActive
  }: MenuOptionsParams
): ContextMenuOption[] => {
  const clear = useClear(dialog, setIsConfirmActive);
  const manager = useManager(dialog, setIsManagerAssignActive);
  const pin = usePin(dialog);
  const read = useRead(dialog);
  const unbind = useUnbind(dialog, setIsManagerUnbindActive);

  const options = [pin, read, manager, unbind, clear].filter(
    option => option != null
  );

  return options as ContextMenuOption[];
};

export default useMenuOptions;
